<template>
  <!-- fd-footer -->
  <footer class="fd-footer">
    <div class="fdn-footer-top">
      <div class="container">
        <div class="fdn-footer-content">
          <div class="fdn-footer-content-brand">
            <img
              v-lazy="imageSiteLogoHorizontal"
              rel="FastEAD"
            >
            <p>Empresa de sociedade limitada registrada sob o CNJP 32.221.157/0001-33 e Razão Social AFG - Serviços de Tecnologia e Consultoria em Educação Ltda. Entregamos soluções para ensino a distância para o mercado B2B e B2C.</p>
          </div>
          <div class="fdn-footer-content-menu">
            <strong>Menu</strong>
            <ul class="fdn-footer-content-menu-list">
              <li class="fd-nav-header-nav-item">
                <a href="/para-empresas">Para empresas</a>
              </li>
              <li class="fd-nav-header-nav-item">
                <a href="/para-escolas">Ensino formal</a>
              </li>
              <li class="fd-nav-header-nav-item">
                <a href="/loja-virtual/1287">Cursos on-demand</a>
              </li>
              <li class="fd-nav-header-nav-item">
                <a href="/videos">Videoteca</a>
              </li>
            </ul>
          </div>
          <div class="fdn-footer-content-blog d-none">
            <strong>últimas postagens do blog</strong>
            <ul
              v-for="noticias in noticiasBlog"
              id="listaUltimasBlog"
              :key="noticias[0]"
              class="fdn-footer-content-blog-posts"
            >
              <li class="fdn-footer-content-blog-posts-item">
                <div
                  class="fdn-footer-content-blog-posts-item-image"
                  :style="{ backgroundImage: 'url(https://concurseiro.com/' + noticias[2] + ')' }"
                />
                <div class="fdn-footer-content-blog-posts-item-text">
                  <a href="#">
                    <strong>Titulo</strong>
                  </a>
                  <p>Lorem ipsum</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="fdn-footer-content-contact">
            <strong>Contatos</strong>
            <ul class="fdn-footer-content-contact-list">
              <li>
                <img v-lazy="imageSiteMapIcon">
                <span>Rua Marabá, 3107 - Lj 01 - Capão da Canoa/RS - 95555-000</span>
              </li>
              <li>
                <img v-lazy="imageSitePhoneIcon">
                <span>{{ fastTelefoneContato }}</span>
              </li>
              <li>
                <a
                  href="https://wa.me/+5551998595714"
                  target="_blank"
                  class="btn btn-primary"
                >Fale com um consultor</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="fdn-footer-bottom">
      <div class="container">
        <span>Todos os direitos reservados @ {{ new Date().getFullYear() }} Power by FastEAD v{{ appVersion }}</span>
      </div>
    </div>
  </footer>
  <!-- /fd-footer -->
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
//import imageSiteLogoHorizontal from "@/assets/images/logo_horizontal.png";
import imageSiteMapIcon from "@/assets/images/map-icon.png";
import imageSitePhoneIcon from "@/assets/images/phone-icon.png";
import {version} from '../../../package'

export default {
  mixins: [methods],
  data: function () {
    return {
      imageSiteLogoHorizontal: "",
      imageSiteMapIcon,
      imageSitePhoneIcon,
      noticiasBlog: [],
      appVersion: version
    };
  },
  computed: {
    fastTelefoneContato() {
      return (
        settings.fastTelefoneContato.slice(0, 3) +
        " (" +
        settings.fastTelefoneContato.slice(3, 5) +
        ")" +
        " " +
        settings.fastTelefoneContato.slice(5)
      );
    },
  },
  mounted() {
    settings
      .fastLogoPrincipal()
      .then((e) => (this.imageSiteLogoHorizontal = e.default));

    if (settings.endApiConcurseiro == "https://api.concurseiro.com/") {
      //this.listaUltimasBlog();
    }
  },
  methods: {
    async listaUltimasBlog() {
      try {
        let resp = await fetch(
          settings.endApiConcurseiro + "api/cargos/listablog",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let arrayNoticiasBlog = Array.from(json).map((e) => e.ItemArray);
        this.noticiasBlog = arrayNoticiasBlog.slice(0, 3);
        //console.log(this.noticiasBlog);
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style></style>
